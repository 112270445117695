import React, { useState } from 'react';

import Modal from '~/components/modal';
import { ReportListContainer } from 'web~/components/popup/report';

import Intro  from '../menu-list/intro';
import Manage  from '../menu-list/manage';
import BusinessManage  from '../menu-list/business-manage';

import { Wrap, MenuButton, MenuNav, Tooltip } from './styled';

interface Props {
  type: 'intro' | 'manage' | 'agent';
  title: string;
  isActive: boolean;
}

function ContainerComponent({ type, title, isActive }: Props) {
  const [isShow, setShow] = useState(false);
  const [isOpenReportList, setOpenReportList] = useState<'' | 'PROCESSING_DETAILS' | 'PENALTY'>('');

  function open() {
    setShow(true);
  }

  function close() {
    setShow(false);
  }

  function clickReportPopupHandler(tab: '' | 'PROCESSING_DETAILS' | 'PENALTY') {
    setOpenReportList(tab);

    if (tab === '') {
      close();
    }
  }

  const showTooltip = !isShow && !isActive && type === 'manage';

  return (
    <>
      <Wrap onMouseEnter={open} onMouseLeave={close}>
        <MenuButton isActive={isActive || isShow}>
          <p>{title}</p>
          {showTooltip && <Tooltip>매물을 등록하고 빠르게 광고를 시작하세요!</Tooltip>}
        </MenuButton>

        {isShow && (
          <MenuNav>
            <div>
              {type === 'intro' ? (
                <Intro close={close} />
              ) : type === 'manage' ? (
                <Manage clickReportPopupHandler={clickReportPopupHandler} close={close} />
              ) : type === 'agent' ? (
                <BusinessManage close={close} />
              ) : null}
            </div>
          </MenuNav>
        )}
      </Wrap>

      {isOpenReportList && (
        <Modal
          minWidth={840}
          handle={() => {}}
          hideClose
          escToClose={false}
        >
          <ReportListContainer
            initTab={isOpenReportList}
            close={clickReportPopupHandler.bind(null, '')}
          />
        </Modal>
      )}
    </>
  );
}

export default ContainerComponent;
