import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import urlList from '~/enum/url-list';

import Logo from '~/components/logo';
import Menu from './components/container';
import Dropdown from './components/dropdown';

import { Header, RigthMenuBar, Link } from './styled';

function HeaderComponent() {
  const [isActiveType, setActiveType] = useState<'' | 'intro' | 'manage'>('');

  const { isAuth, isLoading } = useSelector(({ user }: any) => user);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf('/intro') === 0 || location.pathname === '/') {
      return setActiveType('intro');
    }

    if (urlList.intro.findIndex(i => window.location.pathname.indexOf(i) > -1) > -1) {
      return setActiveType('intro');
    }

    if (urlList.manage.findIndex(i => window.location.pathname.indexOf(i) > -1) > -1) {
      return setActiveType('manage');
    }

    return setActiveType('');
  }, [location]);

  return (
    <Header>
      <div>
        <a href={isAuth ? '/dashboard' : '/'}>
          <Logo width="108" height="24" />
        </a>

        <RigthMenuBar>
          <Menu type="intro" title="다방프로 소개" isActive={isActiveType === 'intro'} />
          {isLoading ? null : isAuth ? (
            <>
              <Menu type="manage" title="중개 관리" isActive={isActiveType === 'manage'} />
              <Menu type="agent" title="업무 관리" isActive={false} />
              <Dropdown />
            </>
          ) : (
            <>
              <Link to="/login">로그인</Link>
              <Link to="/join-consult">
                <p>중개사무소</p>
                <p>가입 및 광고문의</p>
              </Link>
            </>
          )}
        </RigthMenuBar>
      </div>
    </Header>
  );
}

export default HeaderComponent;
