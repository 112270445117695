import React, { useState, useCallback } from 'react';

import useApiCall from '~/hooks/useApiCall';
import { useToast } from '~/components/toast';

import MenuButton from '../menu-button';

import { agentToolDomain } from '~/enum/config';

import { Wrap } from './styled';

function BusinessManageComponent({
  close
}: {
  close: () => void
}) {
  const [isSending, setSending] = useState(false);

  const { post } = useApiCall();
  const toast = useToast();

  const clickLink = useCallback(async (to: string) => {
    if (isSending) return;

    try {
      setSending(true);

      // 회원인지 체크
      const isMember = await post('/api/v2/user/agent-tool-check-signup');

      if (!isMember) {
        // 회원가입
        await post('/api/v2/user/agent-tool-signup');
      }

      // 중개사툴 액세스토큰 발급
      const token = await post(`/api/v2/user/agent-tool-accessible-session`);

      if (token) {
        window.open(`${agentToolDomain}${to}?att=${token}`, '_blank');

      } else {
        toast.error('토큰발급에 실패하였습니다.');
      }

      setSending(false);

      close();

    } catch (err) {
      setSending(false);
      toast.error(err?.msg ?? '토큰발급에 실패하였습니다.');
    }

  }, [isSending]);

  return (
    <Wrap cellWidth="180px">
      <li>
        <p className="title">고객 관리</p>
      
        <MenuButton
          text="고객 관리"
          onClick={clickLink.bind(null, '/customer')}
        />

        <MenuButton
          text="상담 관리"
          onClick={clickLink.bind(null, '/consult')}
        />
      </li>

      <li>
        <p className="title">일정 관리</p>
      
        <MenuButton
          text="일정 관리"
          onClick={clickLink.bind(null, '/schedule')}
        />
      </li>

      <li>
        <p className="title">계약 관리</p>
      
        <MenuButton
          text="계약 내역"
          onClick={clickLink.bind(null, '/contract')}
        />

        <MenuButton
          text="계약서 작성"
          onClick={clickLink.bind(null, '/contract/write')}
        />

        <MenuButton
          text="계약서 양식"
          onClick={clickLink.bind(null, '/contract/templates')}
        />
      </li>

      <li>
        <p className="title">매물장 관리</p>
      
        <MenuButton
          text="매물장"
          onClick={clickLink.bind(null, '/room/list')}
        />
      </li>

      <li>
        <p className="title">단지 관리</p>
      
        <MenuButton
          text="N부동산 모니터링"
          onClick={clickLink.bind(null, '/complex/n-monitoring')}
        />

        <MenuButton
          text="단지 투어"
          onClick={clickLink.bind(null, '/complex/visualization')}
        />
      </li>

      <li>
        <p className="title">모임 관리</p>
      
        <MenuButton
          text="중개 모임"
          onClick={clickLink.bind(null, '/space')}
        />
      </li>

      <li>
        <p className="title">내 정보</p>
      
        <MenuButton
          text="내 정보"
          onClick={clickLink.bind(null, '/my/account')}
        />

        <MenuButton
          text="내 단지"
          onClick={clickLink.bind(null, '/my/complex')}
        />
      </li>
    </Wrap>
  );
}

export default BusinessManageComponent;
