import styled from 'styled-components';

import layout from '~/components/styled/layout';

export const Wrap = styled.div`
  height: 100%;
`;

export const MenuButton = styled.button<{ isActive: boolean }>`
  height: 100%;
  margin: 0;
  padding: 0 24px;
  ${({ theme }) => theme.font.body2.bold};
  color: ${({ isActive, theme }) => isActive ? theme.color.blue[500] : theme.color.gray[900]};
  border: 0;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: calc(100% + 17px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 502;
  }
`;

export const Tooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: 36px;
  ${({ theme }) => theme.font.caption1.regular};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.gray[900]};
  border-radius: 8px;
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  z-index: 501;
  user-select: none;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 0;
    border-right: 5px solid transparent;
    border-bottom: 7px solid ${({ theme }) => theme.color.gray[900]};
    border-left: 5px solid transparent;
    position: absolute;
    top: -7px;
  }
`;

export const MenuNav = styled.nav`
  ${layout};
  padding: 30px 0 80px;
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 500;

  > div {
    display: flex;
  }
`;